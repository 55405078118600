import { FileItemSpanish } from "@files-ui/react"
import * as d3 from "d3"
import { round } from "lodash"

class LocationStackGraphD3 {
  constructor(element, width, height, data) {
    this.element = element
    this.width = width
    this.height = height
    this.data = data
    this.margin = { top: 20, right: 10, bottom: 70, left: 40 }
    this.svg = null

    this.createChart()
  }

  createChart() {
    d3.select(this.element).select("svg").remove()

    if (!this.data || this.data.length === 0) {
      console.warn("Empty data, skipping chart creation.")
      return
    }

    const svg = d3.select(this.element).append("svg").attr("width", this.width).attr("height", this.height)

    const chartGroup = svg.append("g").attr("transform", `translate(${this.margin.left},${this.margin.top})`)

    this.svg = chartGroup

    const innerWidth = this.width - this.margin.left - this.margin.right
    const innerHeight = this.height - this.margin.top - this.margin.bottom

    const parseDate = d3.timeParse("%Y-%m-%d")
    const formatDate = d3.timeFormat("%m/%d")

    // Prepare data
    const dates = this.data.map((d) => {
      const dateKey = Object.keys(d)[0]
      return {
        date: parseDate(dateKey),
        activities: d[dateKey],
        dateString: formatDate(parseDate(dateKey)),
      }
    })

    // Collect all categories for color scale
    const categories = Array.from(
      new Set(
        this.data.flatMap((d) => {
          const dateKey = Object.keys(d)[0]
          return d[dateKey].map((a) => a.category)
        }),
      ),
    )

    // Define scales
    const totalMinutesPerDay = 24 * 60

    const xScale = d3
      .scaleBand()
      .domain(dates.map((d) => d.date))
      .range([0, innerWidth])
      .padding(0.2)

    const yScale = d3.scaleLinear().domain([totalMinutesPerDay, 0]).range([innerHeight, 0])

    const categoriesSequence = [
      "Home",
      "My Home",
      "Other's Home",
      "Work",
      "School",
      "Health",
      "Community",
      "Recreation",
      "Retail",
      "Transportation",
      "Other",
      "Unknown",
    ]
    // Define color scale for categories
    const categoryToColor = {
      Home: "#09de77",
      "My Home": "#09de77",
      "Other's Home": "#a5f5b9",
      Work: "#5e89ff",
      School: "#ffc043",
      Health: "#a4cc3f",
      Community: "#dd6102",
      Recreation: "#eeaac7",
      Retail: "#f85884",
      Transportation: "#888888",
      Other: "#b0b0b0",
      Unknown: "#d1d1d1",
    }

    // Create axes
    const dayAbbreviations = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]
    const xAxis = d3.axisBottom(xScale).tickFormat((d) => dayAbbreviations[d.getDay()])

    const yAxis = d3
      .axisLeft(yScale)
      .tickValues(d3.range(0, 24 * 60, 180))
      .tickFormat((d) => {
        const hours = Math.floor(d / 60)
        return `${hours}:00`
      })

    // Draw activities
    dates.forEach((d, i) => {
      const activities = d.activities

      // Add bars for each activity
      chartGroup
        .selectAll(`.bar-${i}`)
        .data(activities)
        .enter()
        .append("rect")
        .attr("x", xScale(d.date))
        .attr("y", (a) => yScale(this.getMinutesFromTime(a.start_time)))
        .attr("stroke", "white")
        .attr("width", xScale.bandwidth())
        .attr("height", (a) => {
          const startMinutes = this.getMinutesFromTime(a.start_time)
          const endMinutes = this.getMinutesFromTime(a.end_time)
          const y0 = yScale(startMinutes)
          const y1 = yScale(endMinutes)
          return Math.abs(y1 - y0)
        })
        .attr("fill", (a) => categoryToColor[a.category] ?? categoryToColor["Unknown"])
        .on("mouseover", (event, a) => {
          const tooltip = d3.select(this.element).select(".thumbnail-tooltip")
          tooltip
            .html(`${a.start_time.slice(0, 5)} - ${a.end_time.slice(0, 5)} <br/> ${a.category} (${a.name})`)
            .style("left", `${event.pageX + 10}px`)
            .style("top", `${event.pageY - 20}px`)
            .transition()
            .style("opacity", 1)
        })
        .on("mouseout", () => {
          const tooltip = d3.select(this.element).select(".thumbnail-tooltip")
          tooltip.transition().style("opacity", 0)
        })
    })

    // Append axes
    const xAxisG = chartGroup.append("g").attr("transform", `translate(0, ${innerHeight})`).call(xAxis)
    xAxisG.selectAll("path, line").style("stroke", "#888888")

    xAxisG.selectAll("text").attr("dy", "1em").style("color", "#888888").style("text-anchor", "middle")

    // Add weekday and date below
    xAxisG.selectAll(".tick").each(function (d, i) {
      const tick = d3.select(this)
      tick
        .append("text")
        .attr("dy", "3.5em")
        .attr("text-anchor", "middle")
        .style("fill", "#888888")
        .text(dates[i].dateString)
    })

    // Add black rounded rectangle beneath weekend labels
    xAxisG.selectAll(".tick").each(function (d) {
      const day = d.getDay()
      if (day === 0 || day === 6) {
        const tick = d3.select(this)
        const rectHeight = 16
        const rectWidth = 24
        const roundRadius = 6

        tick
          .insert("rect", "text")
          .attr("x", -rectWidth / 2)
          .attr("y", rectHeight / 2)
          .attr("width", rectWidth)
          .attr("height", rectHeight)
          .attr("rx", roundRadius)
          .attr("ry", roundRadius)
          .style("fill", "black")

        tick.select("text").style("fill", "white")
      }
    })

    const yAxisG = chartGroup.append("g").call(yAxis)
    yAxisG.selectAll("path, line").style("stroke", "#888888")
    yAxisG.selectAll("text").style("fill", "#888888")

    this.addTooltip(chartGroup)

    // Add legend at the bottom
    const legendGroup = svg
      .append("g")
      .attr("transform", `translate(${this.margin.left}, ${this.height - this.margin.bottom + 20})`)

    const legendItemSize = 10
    const legendTextLeftMargin = 3
    const itemSpacing = 10
    const legendOffsetX = 0
    const legendOffsetY = 30

    let cumulativeWidth = 0 // Cumulative width of legend items for spacing

    categoriesSequence.forEach((category, i) => {
      if (!categories.includes(category)) {
        return
      }
      const tempText = legendGroup
        .append("text")
        .style("font-size", "12px")
        .style("visibility", "hidden")
        .text(category)

      const textWidth = tempText.node().getBBox().width
      tempText.remove()

      const legendWidth = legendItemSize + legendTextLeftMargin + textWidth

      const legendItem = legendGroup
        .append("g")
        .attr("transform", `translate(${legendOffsetX + cumulativeWidth}, ${legendOffsetY})`)

      // Add color box
      legendItem
        .append("rect")
        .attr("width", legendItemSize)
        .attr("height", legendItemSize)
        .attr("fill", categoryToColor[category] ?? categoryToColor["Unknown"])
        .attr("rx", 4)
        .attr("ry", 4)

      // Add text
      legendItem
        .append("text")
        .attr("x", legendItemSize + legendTextLeftMargin)
        .attr("y", legendItemSize / 2)
        .attr("dy", "0.35em")
        .style("fill", "#888888")
        .style("font-size", "12px")
        .text(category)

      // Update cumulative width
      cumulativeWidth += legendWidth + itemSpacing // Add extra spacing between items if needed
    })
  }

  getMinutesFromTime(time) {
    const [hours, minutes, seconds] = time.split(":").map(Number)
    return hours * 60 + minutes + (seconds || 0) / 60
  }

  addTooltip(svg) {
    d3.select(this.element)
      .append("div")
      .attr("class", "thumbnail-tooltip")
      .style("position", "absolute")
      .style("padding", "4px 12px")
      .style("background", "#000")
      .style("border-radius", "12px")
      .style("color", "#fff")
      .style("font-size", "14px")
      .style("pointer-events", "none")
      .style("opacity", 0)
  }
}

export default LocationStackGraphD3
