import * as d3 from "d3"

import { brightenColor } from "./utils"

class ActivityGraphD3 {
  constructor(element, width, height, data, color = "#5E89FF") {
    this.element = element
    this.width = width
    this.height = height
    this.data = data
    this.margin = { top: 15, right: 10, bottom: 40, left: 40 }
    this.svg = null
    this.unit = data[0].unit
    this.showMinMax = false
    this.color = color

    this.createChart()
  }

  formatValue = (value) => {
    // if value > 1000, convert to k
    if (value > 1000) {
      return `${(value / 1000).toFixed(1)}k`
    }
    return value.toFixed(1)
  }

  createChart() {
    // Remove the old chart if it exists
    d3.select(this.element).select("svg").remove()

    // Configuration options for the graph
    const showAllDots = true
    const showDashLines = true

    // Set up SVG canvas dimensions
    const svg = d3
      .select(this.element)
      .append("svg")
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("viewBox", `0 0 ${this.width} ${this.height}`)
      .append("g")
      .attr("transform", `translate(${this.margin.left},${this.margin.top})`)

    this.svg = svg

    const innerWidth = this.width - this.margin.left - this.margin.right
    const innerHeight = this.height - this.margin.top - this.margin.bottom

    const parseDate = d3.timeParse("%Y-%m-%d")

    // Prepare the data, setting zero values to null
    const data = this.data.map((d) => ({
      date: parseDate(d.date),
      value: d.value === 0 ? null : d.value,
    }))

    // Sort data by date to ensure alignment
    data.sort((a, b) => a.date - b.date)

    // Set up scales
    const xScale = d3
      .scaleBand()
      .domain(data.map((d) => d.date))
      .range([0, innerWidth])
      .padding(0.1)

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.value)])
      .nice()
      .range([innerHeight, 0])

    // Add vertical and horizontal grid lines
    svg
      .append("g")
      .attr("class", "grid")
      .attr("color", "#E7E7E7")
      .call(d3.axisLeft(yScale).ticks(5).tickSize(-innerWidth).tickFormat(""))
      .call((g) => g.select(".domain").remove())

    svg
      .append("g")
      .attr("class", "grid")
      .attr("color", "#E7E7E7")
      .attr("stroke-dasharray", "3, 3")
      .attr("transform", `translate(0, ${innerHeight})`)
      .call(d3.axisBottom(xScale).tickSize(-innerHeight).tickFormat(""))

    // Define the main line that skips null values
    const line = d3
      .line()
      .defined((d) => d.value !== null)
      .x((d) => xScale(d.date) + xScale.bandwidth() / 2)
      .y((d) => yScale(d.value))

    // Append the gradient definition
    const maxValue = d3.max(data, (d) => d.value)
    const gradient = svg
      .append("defs")
      .append("linearGradient")
      .attr("id", `line-gradient-activity-${this.color}`)
      .attr("gradientUnits", "userSpaceOnUse")
      .attr("x1", 0)
      .attr("y1", yScale(0) || 0)
      .attr("x2", 0)
      .attr("y2", 0)

    gradient.append("stop").attr("offset", "0%").attr("stop-color", this.color).attr("stop-opacity", 0)
    gradient.append("stop").attr("offset", "100%").attr("stop-color", this.color).attr("stop-opacity", 0.3)

    // Append the main area
    const area = d3
      .area()
      .defined((d) => d.value !== null)
      .x((d) => xScale(d.date) + xScale.bandwidth() / 2)
      .y0(innerHeight)
      .y1((d) => yScale(d.value))

    svg.append("path").datum(data).attr("fill", `url(#line-gradient-activity-${this.color})`).attr("d", area)

    // Append the main line path (solid line that skips null values)
    svg
      .append("path")
      .datum(data)
      .attr("class", "line")
      .attr("fill", "none")
      .attr("stroke", this.color)
      .attr("stroke-width", 1)
      .style("opacity", 1)
      .attr("d", line)

    // Draw a tiny dot for each non-zero (non-null) data
    if (showAllDots) {
      svg
        .selectAll(".dot-all")
        .data(data.filter((d) => d.value !== null))
        .enter()
        .append("circle")
        .attr("class", "dot-all")
        .attr("cx", (d) => xScale(d.date) + xScale.bandwidth() / 2)
        .attr("cy", (d) => yScale(d.value))
        .attr("r", 3)
        .attr("fill", this.color)

      // add text of value aboce dot
      svg
        .selectAll(".value-label")
        .data(data.filter((d) => d.value !== null))
        .enter()
        .append("text")
        .attr("class", "value-label")
        .attr("x", (d) => xScale(d.date) + xScale.bandwidth() / 2)
        .attr("y", (d) => yScale(d.value) - 6)
        .attr("text-anchor", "middle")
        .attr("font-size", "10px")
        .attr("font-family", "sans-serif")
        .attr("fill", this.color)
        .text((d) => `${this.formatValue(d.value)}`)
    }

    // Draw dashed lines between valid data points with gaps in between
    if (showDashLines) {
      let lastValidPoint = null
      data.forEach((point) => {
        if (point.value !== null) {
          // Draw dashed line from last valid point to current point if there's a gap
          if (
            lastValidPoint &&
            data.slice(data.indexOf(lastValidPoint) + 1, data.indexOf(point)).some((d) => d.value === null)
          ) {
            svg
              .append("line")
              .attr("x1", xScale(lastValidPoint.date) + xScale.bandwidth() / 2)
              .attr("y1", yScale(lastValidPoint.value))
              .attr("x2", xScale(point.date) + xScale.bandwidth() / 2)
              .attr("y2", yScale(point.value))
              .attr("stroke", this.color)
              .attr("stroke-width", 1)
              .attr("stroke-linecap", "round")
              .attr("stroke-dasharray", "2, 3") // Dashed line pattern
              .style("opacity", 0.5)
          }
          lastValidPoint = point
        }
      })
    }

    // Tooltip
    const tooltip = d3
      .select(this.element)
      .append("div")
      .attr("class", "thumbnail-tooltip")
      .style("position", "absolute")
      .style("padding", "4px 12px")
      .style("background", "#000")
      .style("border-radius", "20px")
      .style("color", "#fff")
      .style("font-size", "14px")
      .style("pointer-events", "none")
      .style("opacity", 0)
    if (this.showMinMax) {
      // Filter out data points with a value of null
      const nonZeroData = data.filter((d) => d.value !== null)

      // Find max and min data points, ignoring zeros
      const maxDataPoint = d3.max(nonZeroData, (d) => d.value)
      const minDataPoint = d3.min(nonZeroData, (d) => d.value)

      // Select max and min data points from the filtered data
      const maxPoint = nonZeroData.find((d) => d.value === maxDataPoint)
      const minPoint = nonZeroData.find((d) => d.value === minDataPoint)

      if (maxPoint && minPoint) {
        // Add circles for max and min points
        svg
          .append("circle")
          .attr("cx", xScale(maxPoint.date) + xScale.bandwidth() / 2)
          .attr("cy", yScale(maxPoint.value))
          .attr("r", 4)
          .attr("fill", this.color)

        svg
          .append("circle")
          .attr("cx", xScale(minPoint.date) + xScale.bandwidth() / 2)
          .attr("cy", yScale(minPoint.value))
          .attr("r", 4)
          .attr("fill", this.color)

        // Add text for max and min values
        svg
          .append("text")
          .attr("x", xScale(maxPoint.date) + xScale.bandwidth() / 2)
          .attr("y", yScale(maxPoint.value) - 8)
          .attr("text-anchor", "middle")
          .attr("font-size", "10px")
          .attr("font-family", "sans-serif")
          .attr("fill", "#888888")
          .text(`${Number.isInteger(maxPoint.value) ? maxPoint.value : maxPoint.value.toFixed(1)}${this.unit}`)

        svg
          .append("text")
          .attr("x", xScale(minPoint.date) + xScale.bandwidth() / 2)
          .attr("y", yScale(minPoint.value) + 16)
          .attr("text-anchor", "middle")
          .attr("font-size", "10px")
          .attr("font-family", "sans-serif")
          .attr("fill", "#888888")
          .text(`${Number.isInteger(minPoint.value) ? minPoint.value : minPoint.value.toFixed(1)}${this.unit}`)
      }
    }

    // Vertical line for highlighting
    const focusLine = svg
      .append("line")
      .attr("class", "focusLine")
      .attr("stroke", "#000")
      .attr("stroke-width", 1)
      .attr("stroke-dasharray", "3,3")
      .style("opacity", 0)

    // Circle for highlighting
    const focusCircle = svg.append("circle").attr("r", 5).attr("fill", "#000").style("opacity", 0)

    // Add interaction layer for tooltip
    svg
      .append("rect")
      .attr("class", "overlay")
      .attr("width", innerWidth)
      .attr("height", innerHeight)
      .style("fill", "none")
      .style("pointer-events", "all")
      .on("mousemove", (event) => this.handleMouseMove(event, data, xScale, yScale, tooltip, focusLine, focusCircle))
      .on("mouseout", () => {
        tooltip.style("opacity", 0)
        focusLine.style("opacity", 0)
        focusCircle.style("opacity", 0)
      })

    // Define x and y axes
    const dayAbbreviations = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]
    const xAxis = d3.axisBottom(xScale).tickFormat((d) => dayAbbreviations[d.getDay()])

    const yAxis = d3.axisLeft(yScale).ticks(5)

    // Append axes
    const xAxisG = svg.append("g").attr("transform", `translate(0, ${innerHeight})`).call(xAxis)
    xAxisG.selectAll("path, line").style("stroke", "#888888")

    xAxisG.selectAll("text").attr("dy", "1em").style("text-anchor", "middle").style("color", "#888888")

    // Highlight weekend days on x-axis
    xAxisG.selectAll(".tick").each(function (d) {
      const day = d.getDay()
      if (day === 0 || day === 6) {
        const tick = d3.select(this)
        const rectHeight = 16
        const rectWidth = 18
        const roundRadius = 6

        // Insert a rounded rectangle behind the text
        tick
          .insert("rect", "text")
          .attr("x", -rectWidth / 2)
          .attr("y", rectHeight / 2)
          .attr("width", rectWidth)
          .attr("height", rectHeight)
          .attr("rx", roundRadius)
          .attr("ry", roundRadius)
          .style("fill", "black")

        // Change text color to white
        tick.select("text").style("fill", "white")
      }
    })

    // Add additional date below the weekday labels
    xAxisG
      .selectAll(".tick")
      .append("text")
      .attr("dy", "35px")
      .attr("font-size", "10px")
      .attr("font-family", "sans-serif")
      .attr("fill", "#888888")
      .text((d) => d3.timeFormat("%m/%d")(d))

    const yAxisG = svg.append("g").call(yAxis)
    yAxisG.selectAll("path, line").style("stroke", "#888888")
    yAxisG.selectAll("text").style("fill", "#888888")
  }

  handleMouseMove(event, data, xScale, yScale, tooltip, focusLine, focusCircle) {
    const [xPos] = d3.pointer(event)
    // Get array of x positions
    const xPositions = data.map((d) => xScale(d.date) + xScale.bandwidth() / 2)
    // Find the index of the closest x position
    const index = xPositions.reduce((prevIndex, currX, currIndex) => {
      return Math.abs(currX - xPos) < Math.abs(xPositions[prevIndex] - xPos) ? currIndex : prevIndex
    }, 0)
    const d = data[index]

    // Format date to "MM/DD"
    const formatDate = d3.timeFormat("%m/%d")

    // Show the tooltip
    tooltip
      .html(
        `<span style="color: ${brightenColor(this.color, 0.5)};">${formatDate(d.date)}</span> ${
          d.value === null ? "? " : Number.isInteger(d.value) ? d.value : d.value.toFixed(1)
        }${this.unit}`,
      )
      .style("left", `${event.pageX - 40}px`)
      .style("top", `${event.pageY - 30}px`)
      .style("opacity", 1)

    // Show the vertical line
    focusLine
      .attr("x1", xScale(d.date) + xScale.bandwidth() / 2)
      .attr("x2", xScale(d.date) + xScale.bandwidth() / 2)
      .attr("y1", 0 - this.margin.top)
      .attr("y2", this.height)
      .style("opacity", 0.4)

    // Show the focus circle
    if (d.value !== null && d.value !== 0) {
      focusCircle
        .attr("cx", xScale(d.date) + xScale.bandwidth() / 2)
        .attr("cy", yScale(d.value))
        .style("opacity", 1)
    } else {
      focusCircle.style("opacity", 0)
    }
  }
}

export default ActivityGraphD3
